const { REACT_APP_CUSTOMER_API_URL, REACT_APP_TOKEN_AUTH_ENDPOINT } = process.env

export const apiOrange = `${REACT_APP_CUSTOMER_API_URL}/ofr`;
export const apiSparkle = `${REACT_APP_CUSTOMER_API_URL}/spk`;

//API PARA GENERAR UN BULK, CONSULTAR Y ELIMINAR UN PAQUETE
export const apiProvisionings = `${REACT_APP_CUSTOMER_API_URL}/api/providers/provisionings`;

//API GENERADORA DE PAQUETES
export const apiProviders = `${REACT_APP_CUSTOMER_API_URL}/api/providers/bundles`;

// HEADERS PARA LA AUTENTICACIÓN DE ENDPOINTS 
export const headers = {
    token: REACT_APP_TOKEN_AUTH_ENDPOINT,
  };