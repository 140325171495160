import React, { useEffect, useState } from 'react';
import LayoutHeader from '../../components/LayoutHeader';
import { Alert, Card, CardBody, CardTitle, Col, Form, FormGroup, Button, Input, Row } from 'reactstrap';
import { headers } from 'constants/globals';


const packEndpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/cloudnine/getBundles`;

const initialActivationFormValues = {
	iccid: '',
	pack: 0,
};

const initialDeleteFormValues = {
	iccid: ''
};

const initialResp = {
	activation: {
		type: 'danger',
		message: ''
	},
	delete: {
		type: 'danger',
		message: ''
	}
};

const Cloud9 = () => {
	const [packs, setPacks] = useState([]);
	const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
	const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(false);
	const [packSelected, setPackSelected] = useState(0);
	const [dataInfo, setDataInfo] = useState({});
	const [activationFormValues, setActivationFormValues] = useState(initialActivationFormValues);
	const [deleteFormValues, setDeleteFormValues] = useState(initialDeleteFormValues);
	const [isActivationAlertDisplaying, setIsActivationAlertDisplaying] = useState(false);
	const [isDeleteAlertDisplaying, setIsDeleteAlertDisplaying] = useState(false);
	const [resp, setResp] = useState(initialResp);

	useEffect(() => {
		fetch(packEndpoint, { headers })
			.then(res => res.json())
			.then(data => {
				setPacks(data);
				setPackSelected(data[0]);
				setActivationFormValues({ ...activationFormValues, pack: data[0].bundle_id });
			});
	}, []);

	const showActivationAlert = () => {
		setIsActivationAlertDisplaying(true);
		setTimeout(() => setIsActivationAlertDisplaying(false), 7000);
	};

	const showDeleteAlert = () => {
		setIsDeleteAlertDisplaying(true);
		setTimeout(() => setIsDeleteAlertDisplaying(false), 7000);
	};

	const onActivationChange = (e) => {
		if (e.target.name === 'pack') {
			setPackSelected(e.target.value);
		}
		setActivationFormValues({ ...activationFormValues, [e.target.name]: e.target.value });
	};

	const onDeleteChange = (e) => {
		setDeleteFormValues({ ...deleteFormValues, [e.target.name]: e.target.value });
	};

	return (
		<>
			<LayoutHeader>Cloud9</LayoutHeader>
			<Card>
				<CardBody>
					<CardTitle tag="h5">
						Activación y Top up
					</CardTitle>
					<Form
						onSubmit={e => {
							setSaveBtnIsDisabled(true);
							const { iccid, pack } = activationFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/cloudnine/activation/${iccid}/${pack}`;
							fetch(endpoint, { headers })
								.then(res => res.json()
									.then(data => {
										setResp({
											...resp,
											// activation: {
											// 	type: res.status !== 200 ? 'danger' : 'success',
											// 	message: res.status !== 200 ? `ERROR: ${data.message}` : ``
											// }
											activation: {
												message: data.message ? `ERROR: ${data.message}` : `OK`
											}
										});
										showActivationAlert();
										setSaveBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										activation: {
											type: 'danger',
											message: error.message
										}
									});
									showActivationAlert();
									setIsActivationAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationChange}
										name='iccid'
										placeholder='# Iccid'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										type='select'
										value={packSelected}
										name='pack'
										onChange={onActivationChange}
									>
										{
											Array.isArray(packs) && packs?.map((item, idx) => <option value={item.bundle_id} key={idx}>{item.bundle_name}</option>)
										}
									</Input>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='info' disabled={
											saveBtnIsDisabled || 
											!activationFormValues.iccid ||
											!activationFormValues.pack
										} 
										title='Submit'
									>
										Activar y Top up
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{isActivationAlertDisplaying && <Alert color={resp.activation.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp.activation.message)}
					</Alert>
					}
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle tag="h5">
						Consultar
					</CardTitle>
					<Form
						onSubmit={e => {
							setDeleteBtnIsDisabled(true);
							const { iccid } = deleteFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/cloudnine/getStatus/${iccid}`;
							fetch(endpoint, { headers })
								.then(res => res.json()
									.then(data => {
										setDataInfo(data);
										setResp({
											...resp,
											delete: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: res.status !== 200 ? data.message : `${iccid} desactive process: ${data.message}`
											}
										});
										if(res.status !== 200) {
											showDeleteAlert();
										}
										setDeleteBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										delete: {
											type: 'danger',
											message: error.message
										}
									});
									showDeleteAlert();
									setIsDeleteAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onDeleteChange}
										name='iccid'
										placeholder='# Iccid'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='info' disabled={deleteBtnIsDisabled || !deleteFormValues.iccid} title='Submit' >
										Consultar
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{dataInfo.remainingDataMb !== undefined && dataInfo.usedDataPercent !== undefined && <>
						<h6>{dataInfo.remainingDataMb.toFixed(2)} Mb restantes</h6>
						<h6>{dataInfo.usedDataPercent.toFixed(2)}% de datos usados</h6>
					</>
					}
					{isDeleteAlertDisplaying && <Alert color={resp.delete.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp.delete.message)}
					</Alert>

					}
				</CardBody>
			</Card>
		</>
	);
};

export default Cloud9;
