import React, { useEffect, useState } from 'react';
import LayoutHeader from '../../components/LayoutHeader';
import { Alert, Card, CardBody, CardTitle, Col, Form, FormGroup, Button, Input, Row } from 'reactstrap';
import ChinaMobileUploader from './ChinaMobileUploader';
import { headers } from 'constants/globals';

const packEndpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/chinamobile/getBundles`;

const initialActivationFormValues = {
	iccid: '',
	pack: 0,
};

const initialActivationBulkFormValues = {
	iccids: [],
	pack: 0,
	bagName: ''
};

const initialDeleteFormValues = {
	iccid: '',
	pack: 0
};

const initialConsultFormValues = {
	iccid: '',
};

const initialBundleInfoFormValues = {
	iccid: '',
};

const initialResp = {
	activation: {
		type: 'danger',
		message: ''
	},
	delete: {
		type: 'danger',
		message: ''
	}
};

const ChinaMobile = () => {
	const [packs, setPacks] = useState([]);
	const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
	const [bundleInfoBtnIsDisabled, setBundleInfoBtnIsDisabled] = useState(false);
	const [saveBulkBtnIsDisabled, setSaveBulkBtnIsDisabled] = useState(false);
	const [consultBtnIsDisabled, setConsultBtnIsDisabled] = useState(false);
	const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(false);
	const [dataInfo, setDataInfo] = useState({});
	const [bundleInfo, setBundleInfo] = useState({});
	const [packSelected, setPackSelected] = useState(0);
	const [packBulkSelected, setPackBulkSelected] = useState(0);
	const [packDeleteSelected, setPackDeleteSelected] = useState(0);
	const [activationFormValues, setActivationFormValues] = useState(initialActivationFormValues);
	const [activationBulkFormValues, setActivationBulkFormValues] = useState(initialActivationBulkFormValues);
	const [bundleInfoFormValues, setBundleInfoFormValues] = useState(initialBundleInfoFormValues);
	const [consultFormValues, setConsultFormValues] = useState(initialConsultFormValues);
	const [deleteFormValues, setDeleteFormValues] = useState(initialDeleteFormValues);
	const [isActivationAlertDisplaying, setIsActivationAlertDisplaying] = useState(false);
	const [isActivationBulkAlertDisplaying, setIsActivationBulkAlertDisplaying] = useState(false);
	const [isBundleInfoAlertDisplaying, setIsBundleInfoAlertDisplaying] = useState(false);
	const [isConsultAlertDisplaying, setIsConsultAlertDisplaying] = useState(false);
	const [isDeleteAlertDisplaying, setIsDeleteAlertDisplaying] = useState(false);
	const [resp, setResp] = useState(initialResp);
	const [hasDuplicateIccid, setHasDuplicateIccid] = useState(false);

	useEffect(() => {
		fetch(packEndpoint,{ headers })
			.then(res => res.json())
			.then(data => {
				const processedPacks = [
					{
						value: '',
						key: 'Selecciona un bundle',
						description: ''
					},
					...data
				]
				setPacks(processedPacks);
				setPackSelected(processedPacks[0]);
				setActivationFormValues({ ...activationFormValues, pack: processedPacks[0].value });
				setActivationBulkFormValues({ ...activationBulkFormValues, pack: processedPacks[0].value });
			});
	}, []);

	const showActivationAlert = () => {
		setIsActivationAlertDisplaying(true);
		setTimeout(() => setIsActivationAlertDisplaying(false), 7000);
	};

	const showActivationBulkAlert = () => {
		setIsActivationBulkAlertDisplaying(true);
		setTimeout(() => setIsActivationBulkAlertDisplaying(false), 7000);
	};

	const showBundleInfoAlert = () => {
		setIsBundleInfoAlertDisplaying(true);
		setTimeout(() => setIsBundleInfoAlertDisplaying(false), 7000);
	};

	const showConsultAlert = () => {
		setIsConsultAlertDisplaying(true);
		setTimeout(() => setIsConsultAlertDisplaying(false), 7000);
	};

	const showDeleteAlert = () => {
		setIsDeleteAlertDisplaying(true);
		setTimeout(() => setIsDeleteAlertDisplaying(false), 7000);
	};

	const onActivationChange = (e) => {
		if (e.target.name === 'pack') {
			setPackSelected(e.target.value);
		}
		setActivationFormValues({ ...activationFormValues, [e.target.name]: e.target.value });
	};

	const onActivationBulkChange = (e) => {
		if (e.target.name === 'pack') {
			setPackBulkSelected(e.target.value);
		}
		setActivationBulkFormValues({ ...activationBulkFormValues, [e.target.name]: e.target.value });
	};

	const onIccidsLoad = (iccids) => {
		if(Array.isArray(iccids)){
			const isDuplicate = iccids.some((item, idx) => iccids.indexOf(item) != idx);
			setHasDuplicateIccid(isDuplicate)
			setActivationBulkFormValues({ ...activationBulkFormValues, iccids});
		}
	};

	const onDeleteChange = (e) => {
		if (e.target.name === 'pack') {
			setPackDeleteSelected(e.target.value);
		}
		setDeleteFormValues({ ...deleteFormValues, [e.target.name]: e.target.value });
	};

	const onConsultChange = (e) => {
		setConsultFormValues({ ...consultFormValues, [e.target.name]: e.target.value });
	};

	const onBundleInfoChange = (e) => {
		setBundleInfoFormValues({ ...bundleInfoFormValues, [e.target.name]: e.target.value });
	};

	return (
		<>
			<LayoutHeader>China Mobile</LayoutHeader>
			<Card>
				<CardBody>
					<CardTitle tag="h5">
						Activación y Top up
					</CardTitle>
					<Form
						onSubmit={e => {
							setSaveBtnIsDisabled(true);
							const { iccid, pack } = activationFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/chinamobile/addBundle/${iccid}/${pack}`;
							fetch(endpoint, { headers })
								.then(res => res.json()
									.then(data => {
										setResp({
											...resp,
											activation: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: data.message
											}
										});
										showActivationAlert();
										setSaveBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										activation: {
											type: 'danger',
											message: error.message
										}
									});
									showActivationAlert();
									setIsActivationAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationChange}
										name='iccid'
										placeholder='# Iccid'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										type='select'
										value={packSelected}
										name='pack'
										onChange={onActivationChange}
									>
										{
											Array.isArray(packs) && packs?.map((item, idx) => <option value={item.value} key={idx}>{item.key}</option>)
										}
									</Input>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='info' disabled={
											saveBtnIsDisabled || 
											!activationFormValues.iccid ||
											!activationFormValues.pack
										} 
										title='Submit'
									>
										Activar y Top up
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{isActivationAlertDisplaying && <Alert color={resp.activation.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp.activation.message)}
					</Alert>
					}
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle tag="h5">
						Consultar
					</CardTitle>
					<Form
						onSubmit={e => {
							setConsultBtnIsDisabled(true);
							const { iccid } = consultFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/chinamobile/getConsumedData/${iccid}`;
							fetch(endpoint, { headers })
								.then(res => res.json()
									.then(data => {
										setDataInfo(data);
										setResp({
											...resp,
											delete: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: res.status !== 200 ? data.message : `${iccid} desactive process: ${data.message}`
											}
										});
										if(res.status !== 200) {
											showConsultAlert();
										}
										setConsultBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										delete: {
											type: 'danger',
											message: error.message
										}
									});
									showConsultAlert();
									setIsConsultAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onConsultChange}
										name='iccid'
										placeholder='# Iccid'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='info' disabled={consultBtnIsDisabled || !consultFormValues.iccid} title='Submit' >
										Consultar
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{dataInfo?.remainingDataMb !== undefined && dataInfo?.usedDataPercent !== undefined && <>
						<h6>{parseFloat(dataInfo?.usedData)?.toFixed(2)} Mb usados</h6>
						<h6>{dataInfo?.usedDataPercent?.toFixed(2)}% de datos usados</h6>
						<progress  max="100" value={dataInfo?.usedDataPercent?.toFixed(2)}></progress><br/>
						<h6>--------------------------------</h6><br/>						
						<h6>{parseFloat(dataInfo?.remainingDataMb)?.toFixed(2)} Mb restantes</h6>						
						<h6>{(100 - dataInfo?.usedDataPercent)?.toFixed(2)}% Restante</h6>
					</>
					}
					{isConsultAlertDisplaying && <Alert color={resp.delete.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp?.delete?.message)}
					</Alert>

					}
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle tag="h5">
						Consultar información bundle
					</CardTitle>
					<Form
						onSubmit={e => {
							setBundleInfoBtnIsDisabled(true);
							const { iccid } = bundleInfoFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/chinamobile/getBundleInfo/${iccid}`;
							fetch(endpoint, { headers })
								.then(res => res.json()
									.then(data => {
										setBundleInfo(data);
										setResp({
											...resp,
											delete: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: res.status !== 200 ? data.message : `${iccid} desactive process: ${data.message}`
											}
										});
										if(res.status !== 200) {
											showBundleInfoAlert();
										}
										setBundleInfoBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										delete: {
											type: 'danger',
											message: error.message
										}
									});
									showBundleInfoAlert();
									setIsBundleInfoAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onBundleInfoChange}
										name='iccid'
										placeholder='# Iccid'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='info' disabled={bundleInfoBtnIsDisabled || !bundleInfoFormValues.iccid} title='Submit' >
										Consultar
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{Array.isArray(bundleInfo.bundles) && bundleInfo.bundles.map(bundle => <>
						<h6>Name: {bundle.bundleName}</h6>
						<ul>
							<li><h6>Create Time: {bundle.bundleCreateTime}</h6></li>
							<li><h6>Description: {bundle.bundleDescription}</h6></li>
							<li><h6>End Time: {bundle.bundleEndTime}</h6></li>
							<li><h6>Expire Time: {bundle.bundleExpireTime}</h6></li>
						</ul>
					</>)
					}
					{isBundleInfoAlertDisplaying && <Alert color={resp.delete.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp.delete.message)}
					</Alert>

					}
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle tag="h5">
						Activación Bulk
					</CardTitle>
					<Form
						onSubmit={e => {
							setSaveBulkBtnIsDisabled(true);
							const { iccids, pack, bagName } = activationBulkFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/chinamobile/addBundleBulk`;
							fetch(endpoint, {
								headers,
								method: 'POST',
								body: JSON.stringify({
									bundleId: pack,
									iccids: iccids,
									bagName
								}) 
							})
								.then(res => res.json()
									.then(data => {
										setResp({
											...resp,
											activation: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: data.message
											}
										});
										showActivationBulkAlert();
										setSaveBulkBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										activation: {
											type: 'danger',
											message: error.message
										}
									});
									showActivationBulkAlert();
									setIsActivationBulkAlertDisplaying(false);
								});
						}}
					>
						<Row>
							{/* <Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationBulkChange}
										name='iccidStart'
										placeholder='# Iccid de inicio'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationBulkChange}
										name='iccidEnd'
										placeholder='# Iccid final'
									/>
								</FormGroup>
							</Col> */}
							<Col xs={12} md={4}>
								<FormGroup>
									<Input
										onChange={onActivationBulkChange}
										name='bagName'
										placeholder='# Nombre de la bolsa'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input
										type='select'
										value={packBulkSelected}
										name='pack'
										onChange={onActivationBulkChange}
									>
										{
											Array.isArray(packs) && packs?.map((item, idx) => <option value={item.value} key={idx}>{item.key}</option>)
										}
									</Input>
								</FormGroup>
							</Col>
							{/*<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationBulkChange}
										type="email"
										name='email'
										placeholder='# Email'
									/>
								</FormGroup>
							</Col>*/}
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='info' disabled={ 
											saveBulkBtnIsDisabled || 
											activationBulkFormValues === undefined ||
											activationBulkFormValues?.iccids?.length === 0 ||
											hasDuplicateIccid ||
											!activationBulkFormValues.pack ||
											!activationBulkFormValues?.bagName
										} 
										title='Submit'
									>
										Bulk
									</Button>
								</FormGroup>
							</Col>
						</Row>
						<Alert color="warning" className='text-center animated fadeDown'>
							<p>El excel debe "solo" contener las siguientes columnas en el siguiente orden</p>
							<p>| iccid |</p>
						</Alert>
						<ChinaMobileUploader onIccidsLoad={onIccidsLoad}/>
					</Form>
					{isActivationBulkAlertDisplaying && <Alert color={resp.activation.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp.activation.message)}
					</Alert>
					}
				</CardBody>
			</Card>

				<Card>
				<CardBody>
					<CardTitle tag="h5">
						Desactivar
					</CardTitle>
					<Form
						onSubmit={e => {
							setDeleteBtnIsDisabled(true);
							const { iccid, pack } = deleteFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/chinamobile/deleteBundle/${iccid}/${pack}`;
							fetch(endpoint, { headers })
								.then(res => res.json()
									.then(data => {
										setResp({
											...resp,
											delete: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: data.message
											}
										});
										showDeleteAlert();
										setDeleteBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										delete: {
											type: 'danger',
											message: error.message
										}
									});
									showDeleteAlert();
									setIsDeleteAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onDeleteChange}
										name='iccid'
										placeholder='# Iccid'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										type='select'
										value={packDeleteSelected}
										name='pack'
										onChange={onDeleteChange}
									>
										{
											Array.isArray(packs) && packs?.map((item, idx) => <option value={item.value} key={idx}>{item.key}</option>)
										}
									</Input>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='danger' disabled={deleteBtnIsDisabled || !deleteFormValues.iccid || !deleteFormValues.pack} title='Submit' >
										Desactivar
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{isDeleteAlertDisplaying && <Alert color={resp.delete.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp.delete.message)}
					</Alert>
					}
				</CardBody>
			</Card>
		</>
	);
};

export default ChinaMobile;
